import React, { useEffect, useState } from "react";
import Die from "./Die";
import { nanoid } from "nanoid";
import Confetti from "react-confetti";


const start = Math.round(new Date() / 1000);
  console.log(start);
export default function App() {
  const [dice, setDice] = useState(allNewDice());
  const [tenzies, setTenzies] = useState(false);
  const [count, setcount] = useState(0);
  const [FinalScore, setFinalScore] = useState();
  const [Data, setData] = useState();
  

  useEffect(() => {
    const allHeld = dice.every((die) => die.isHeld);
    const firstValue = dice[0].value;
    const allSameValue = dice.every((die) => die.value === firstValue);

    if (allHeld && allSameValue) {
      setTenzies(true);
      setcount(-1);
      setFinalScore(count);
      const end = Math.round(new Date() / 1000);
      console.log(end);
      const result = end - start;
      console.log(result);
      setData(result);
    }
  }, [dice]);

  function generateNewDie() {
    return {
      value: Math.ceil(Math.random() * 6),
      isHeld: false,
      id: nanoid(),
    };
  }

  function allNewDice() {
    const newDice = [];
    for (let i = 0; i < 10; i++) {
      newDice.push(generateNewDie());
    }
    return newDice;
  }

  function rollDice() {
    if (!tenzies) {
      setDice((oldDice) =>
        oldDice.map((die) => {
          return die.isHeld ? die : generateNewDie();
        })
      );
    } else {
      setTenzies(false);
      setDice(allNewDice());
    }
    setcount((prev) => prev + 1);
  }

  function holdDice(id) {
    setDice((oldDice) =>
      oldDice.map((die) => {
        return die.id === id ? { ...die, isHeld: !die.isHeld } : die;
      })
    );
  }

  const diceElements = dice.map((die) => (
    <Die
      key={die.id}
      value={die.value}
      isHeld={die.isHeld}
      holdDice={() => holdDice(die.id)}
    />
  ));

  return (
    <div className="container">
      <main>
        {tenzies &&
        
        <div>
        <Confetti />
        <h1 className="time"  > You spend {Data} s to finish this came </h1>

        </div>
        
         
         }
        <span className="numOfRoles" > {!tenzies && ` number of rules : ${count > -1 && count}`} </span>

        <h1 className="title">
          {tenzies ? `You won after ${FinalScore} rolles` : "Tenzies"}
        </h1>
        <h2 className="instructions">
          Roll until all dice are the same. Click each die to freeze it at its
          current value between rolls.
        </h2>
        <div className="dice-container">{diceElements}</div>
        <button className="roll-dice" onClick={rollDice}>
          {tenzies ? "New Game" : "Roll"}
        </button>
      </main>
    </div>
  );
}
